<template>
  <a-tree-select
      :value="val"
      style="width: 100%"
      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
      :tree-data="treeData"
      :replaceFields="replaceFields"
      placeholder="请选择所属部门"
      tree-default-expand-all
      :multiple="multiple"
      :disabled="disabled"
      @change="chooseItem"
  >
  </a-tree-select>
</template>

<script>
import { getOrgTree } from '@/api/humanResources/organization'
export default {
  name: 'selectDepartment',
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: [Number, String, Array],
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      treeData: [],
      replaceFields: {
        title: 'deptName',
        key: 'id',
        value: 'id'
      },
      val: '',
      label: ''
    }
  },
  created () {
    this.val = this.value
  },
  mounted () {
    this.getTree()
  },
  methods: {
    getTree () {
      getOrgTree().then(res => {
        this.treeData = res.data
      })
    },
    chooseItem (value, label, extra) {
      this.val = value
      this.$emit("change", value);
      this.$emit("deptChange", value);
    }
  },
  watch: {
    value (n, o) {
      this.val = n
    }
  }
}
</script>

<style scoped>

</style>
