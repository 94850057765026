<template>
  <div class="detail-page">
    <div class="handle-area">
      <a-button class="margin-l40 width-120" type="danger" icon="delete" @click="delItem" v-has-permission="['deleteStaff']">删除</a-button>
    </div>
    <a-row :gutter="10">
      <a-col span="13">
        <a-card title="个人信息" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="infoSubmit" v-has-permission="['editStaff']">保存</a-button>
          </div>
          <a-form-model
              ref="infoForm"
              labelAlign="right"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 17 }"
              :model="detail"
              :rules="infoRules"
              :hideRequiredMark="true"
          >
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="姓名" prop="staffName">
                  <a-input placeholder="请输入姓名" v-model="detail.staffName" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item label="婚姻状况" prop="maritalStatus">
                  <a-select v-model="detail.maritalStatus" placeholder="请选择婚姻状况" :disabled="!canModify">
                    <a-select-option :value="1">已婚</a-select-option>
                    <a-select-option :value="0">未婚</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="性别" prop="gender">
                  <a-select v-model="detail.gender" placeholder="请选择性别" :disabled="!canModify">
                    <a-select-option :value="1">男</a-select-option>
                    <a-select-option :value="2">女</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item label="政治面貌" prop="politicalOutlook" :disabled="!canModify">
                  <a-input placeholder="请输入政治面貌" v-model="detail.politicalOutlook" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="身份证号" prop="idNo">
                  <a-input placeholder="请输入身份证号" v-model="detail.idNo" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item label="联系电话" prop="phone">
                  <a-input placeholder="请输入联系电话" v-model="detail.phone" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="出生日期" prop="birthday">
                  <a-date-picker
                      :value="detail.birthday ? moment(detail.birthday) : null"
                      @change="(date, dateStr) => dateChange(date, dateStr, 'birthday')"
                      style="width: 100%"
                      :disabled="!canModify"
                  />
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item label="微信" prop="wechat">
                  <a-input placeholder="请输入微信" v-model="detail.wechat" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="民族" prop="nation">
                  <a-input placeholder="请输入民族" v-model="detail.nation" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item label="QQ" prop="qq">
                  <a-input placeholder="请输入QQ" v-model="detail.qq" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="籍贯" prop="nativePlace">
                  <a-input placeholder="请输入籍贯" v-model="detail.nativePlace" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item label="邮箱" prop="email">
                  <a-input placeholder="请输入邮箱" v-model="detail.email" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row class="" :gutter="10">
              <a-col span="12">
                <a-form-model-item label="住址" prop="address">
                  <a-input placeholder="请输入住址" v-model="detail.address" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </a-col>
      <a-col span="5">
        <a-card title="岗位信息" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="infoSubmit" v-has-permission="['editStaff']">保存</a-button>
          </div>
          <a-form-model
              ref="form"
              labelAlign="right"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              :model="detail"
              :rules="postRules"
              :hideRequiredMark="true"
          >
            <a-row>
              <a-form-model-item label="员工编号" prop="staffNo">
                <a-input placeholder="请输入员工编号" v-model="detail.staffNo"  :disabled="!canModify"/>
              </a-form-model-item>
              <a-form-model-item  label="所属部门" prop="deptId">
                <SelectDept :multiple="false" v-model="detail.deptId" @deptChange="deptChange" :disabled="!canModify"></SelectDept>
              </a-form-model-item>
              <a-form-model-item label="岗位" prop="postId">
                <a-select v-model="detail.postId" placeholder="请选择岗位" :disabled="!canModify">
                  <a-select-option :value="item.id" v-for="(item) in deptList" :key="item.id">{{ item.postName }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="岗位性质" prop="wechat">
                <span v-if="detail.postType === 1">全职</span>
                <span v-if="detail.postType === 2">兼职</span>
                <span v-if="detail.postType === 3">实习</span>
              </a-form-model-item>
              <a-form-model-item label="员工状态" prop="staffStatus">
                <a-select v-model="detail.staffStatus" placeholder="请选择员工状态" :disabled="!canModify">
                  <a-select-option :value="1">在职</a-select-option>
                  <a-select-option :value="2">离职</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="直属领导" prop="directLeader">
                <div @click="openStaff">
                  <a-input-search placeholder="直属领导" v-model="detail.directLeader" disabled></a-input-search>
                </div>
              </a-form-model-item>
              <a-form-model-item label="系统账号" prop="staffStatus">
                <span>{{ detail.account }}</span>
              </a-form-model-item>
            </a-row>
          </a-form-model>
        </a-card>
      </a-col>
      <a-col span="6">
        <a-card title="参与项目" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <span>{{ detail.staffProjectInfo.totalProjectNum }}（{{ detail.staffProjectInfo.goingProjectNum }}）</span>
          </div>
          <div class="line-h30">
            <div class="display-f justify-between" v-for="(item, index) in detail.staffProjectInfo.projectInfos" :key="item.id">
              <span>{{ item.projectName }}</span>
              <span class="font-c9"> {{ projectStatusName[item.projectStatus] }}</span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="18">
        <EditEducation
            :staffId="$route.query.id"
            :value="detail.educations"
            v-if="detail.educations"
            :canModify="canModify"
        />
      </a-col>
      <a-col span="6">
        <a-card title="参与任务" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <span>{{ detail.staffTaskInfo.totalTaskNum }}（{{ detail.staffTaskInfo.goingTaskNum }}）</span>
          </div>
          <div class="line-h30">
            <div class="display-f justify-between" v-for="(item, index) in detail.staffTaskInfo.projectTasks" :key="item.id">
              <span>{{ item.taskName }}</span>
              <span class="font-c9"> {{ taskStatusName[item.taskStatus] }}</span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="18">
        <EditExperience
            :staffId="$route.query.id"
            :value="detail.workExperiences"
            v-if="detail.workExperiences"
            :canModify="canModify"
        />
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="18">
        <a-card title="日志">
          <a-table
              :columns="logColumns"
              :data-source="detail.projectLogs"
              rowKey="id"
              bordered
          ></a-table>
        </a-card>
      </a-col>
    </a-row>
    <SelectStaff
        :visible="staffShow"
        :type="1"
        @submit="staffChange"
        @close="staffShow = false"
        v-if="staffShow"
    ></SelectStaff>
  </div>
</template>

<script>
import moment from 'moment'
import { getJobList, } from '../../api/humanResources/job'
import { createStaff, deleteStaff, getEmployeeDetail } from '../../api/humanResources/staff'
import SelectDept from '@/components/select/selectDept'
import SelectStaff from '@/components/select/selectStaff'
import { mapState } from 'vuex'
import EditEducation from './editEducation'
import EditExperience from './editExperience'
import { deleteCustomer } from '../../api/customer/customer'
export default {
  name: 'employeeDetail',
  components: {
    SelectDept,
    SelectStaff,
    EditEducation,
    EditExperience
  },
  data () {
    return {
      detail: {
        staffProjectInfo: {
          projectInfos: []
        },
        staffTaskInfo: {}
      },
      canModify: true,
      infoRules: {
        staffName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      },
      staffShow: false,
      postForm: {},
      postRules: {
        staffNo: [
          { required: true, message: '请输入员工编号', trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: '请选择所属部门', trigger: 'change' }
        ],
        postId: [
          { required: true, message: '请选择岗位', trigger: 'change' }
        ]
      },
      deptList: [],
      projectStatusName: ['', '洽谈中', '规划中', '实施中', '验收中', '已完成', '已取消', '已搁置'],
      taskStatusName: ['未开始', '审批中', '进行中', '已完成', '已搁置', '已取消'],
      logColumns: [
        {
          title: '时间',
          dataIndex: 'createTime'
        },
        {
          title: '事件内容',
          dataIndex: 'logContent'
        },
        {
          title: '触发人',
          dataIndex: 'creator'
        }
      ],
    }
  },
  mounted () {
    this.getDetail()
    this.canModify = this.authority.editStaff
  },
  methods: {
    moment,
    getDetail () {
      getEmployeeDetail({
        id: this.$route.query.id
      }).then(res => {
        this.detail = res.data
        if (this.detail.deptId) this.getJobList(this.detail.deptId)
      })
    },
    dateChange (date, dateString, type) {
      this.detail[type] = dateString
    },
    infoSubmit () {
      this.$refs.infoForm.validate(valid => {
        if (valid) {
          createStaff(this.detail).then(() => {
            this.$message.success('操作成功！')
            this.getDetail()
          })
        }
      })
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteStaff({
            id: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getMember()
          })
        }
      })
    },
    staffChange (data) {
      this.detail.directLeaderId = data.id
      this.detail.directLeader = data.staffName
      this.$forceUpdate()
      this.staffShow = false
    },
    deptChange (value) {
      this.detail.postId = ''
      this.getJobList(value)
    },
    getJobList (deptId) {
      getJobList({
        deptId: deptId,
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.deptList = res.data.list
      })
    },
    openStaff () {
      if (this.canModify) this.staffShow = true
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      userInfo: state => state.user.info,
      authority: state => state.user.authority
    }),
  }
}
</script>

<style scoped>

</style>
