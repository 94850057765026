<template>
  <a-modal
      :visible="visible"
      :title="'请选择' + titleList[type]"
      centered
      :footer="null"
      @cancel="closeSelect"
  >
    <a-table
        :columns="columns"
        :data-source="list"
        :loading="tableLoading"
        :pagination="pagination"
        rowKey="id"
        bordered
        @change="pageChange"
    >
      <template slot="customerLevel" slot-scope="text">
        <span v-if="text === 1">重要</span>
        <span v-if="text === 2">普通</span>
        <span v-if="text === 3">非优</span>
      </template>
      <template slot="stakeholderLevel" slot-scope="text">
        <span v-if="text === 1">主要干系人</span>
        <span v-if="text === 2">干系人</span>
      </template>
      <template slot="id" slot-scope="text, record">
        <span class="dominant cursor" @click="tableChange(record)">选择</span>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { getStaffList } from '@/api/humanResources/staff'
import { getCustomerList } from '@/api/customer/customer'
import { getCustomerStakeholderList } from '../../api/project/projectDetail'

export default {
  name: 'chooseUser',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    value: {
      type: [String, Array, Object, Number]
    }
  },
  data () {
    return {
      columns: [],
      list: [],
      page: 1,
      rowSelection: {
        onChange: this.tableChange
      },
      selectedRows: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      titleList: ['', '员工', '客户', '干系人']
    }
  },
  mounted () {
    switch (this.type) {
      case 1:
        this.columns = [
          {
            dataIndex: 'staffNo',
            title: '员工编号'
          },
          {
            dataIndex: 'staffName',
            title: '员工姓名'
          },
          {
            dataIndex: 'deptName',
            title: '所属部门'
          },
          {
            dataIndex: 'id',
            title: '操作',
            scopedSlots: { customRender: 'id' }
          }
        ]
        break
      case 2:
        this.columns = [
          {
            dataIndex: 'customerName',
            title: '客户姓名'
          },
          {
            dataIndex: 'customerLevel',
            title: '客户级别',
            scopedSlots: { customRender: 'customerLevel' }
          },
          {
            dataIndex: 'id',
            title: '操作',
            scopedSlots: { customRender: 'id' }
          }
        ]
            break
      case 3:
        this.columns = [
          {
            dataIndex: 'stakeholderName',
            title: '干系人姓名'
          },
          {
            dataIndex: 'stakeholderLevel',
            title: '重要程度',
            scopedSlots: { customRender: 'stakeholderLevel' }
          },
          {
            dataIndex: 'phone',
            title: '联系电话'
          },
          {
            dataIndex: 'id',
            title: '操作',
            scopedSlots: { customRender: 'id' }
          }
        ]
            break
    }
    this.getList()
  },
  methods: {
    getList () {
      this.tableLoading = true
      switch (this.type) {
        case 1:
          getStaffList({
            pageNum: this.page,
            pageSize: 10
          }).then(res => {
            this.list = res.data.list
            this.pagination.total = res.data.total
            this.tableLoading = false
          })
          break
        case 2:
          getCustomerList({
            pageNum: this.page,
            pageSize: 10
          }).then(res => {
            this.list = res.data.list
            this.pagination.total = res.data.total
            this.tableLoading = false
          })
          break
        case 3:
          getCustomerStakeholderList({
            customerId: this.value
          }).then(res => {
            this.list = res.data
            this.tableLoading = false
          })
              break
      }
    },
    tableChange (item) {
      this.$emit('submit', item)
    },
    pageChange (pages) {
      this.page = pages.current
      this.getList()
    },
    closeSelect () {
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped>

</style>
